<template>
    <div>redirecting...</div>
</template>

<script setup>
const auth = useAuthStore();

onMounted(() => {
    auth.signIn();
});

definePageMeta({ name: 'auth-sign-in' });
</script>

<style lang="scss" scoped></style>
